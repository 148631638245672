import { useMemo, useState } from 'react'
import dynamic from 'next/dynamic'
import cn from 'classnames'

import { DynamicComponentProps } from 'components/dynamic-components/types'
import { Container } from 'components/container'
import { ExposureTracker } from 'components/exposure-tracker'
import { CardSlider } from 'components/card-slider'
import { KfRewardsModalProps } from 'components/kf-rewards-modal'
import { ExclusivePassengerPerksModalProps } from 'components/exclusive-passenger-perks-modal'

import { useToggle } from 'lib/hooks/useToggle'

import { useGlobalContext } from 'lib/context/global-context'

import { EVENTS } from 'lib/constants/events'

import { ExclusiveBenefitData, ExclusiveBenefitsCard } from '../exclusive-benefits-card'

import s from './styles.module.scss'

const KfRewardsModal = dynamic<KfRewardsModalProps>(() =>
  import('components/kf-rewards-modal').then((mod: any) => mod.KfRewardsModal)
)
const ExclusivePassengerPerksModal = dynamic<ExclusivePassengerPerksModalProps>(() =>
  import('components/exclusive-passenger-perks-modal').then((mod: any) => mod.ExclusivePassengerPerksModal)
)

type ExclusiveBenefitsProps = DynamicComponentProps & {
  trackEvent: TrackEventType
}

type ComponentContent = {
  header: string
  items: ExclusiveBenefitData[]
}

type PerksModalBrandName = Exclude<ExclusiveBenefitData['id'], 'kf'>

const ExclusiveBenefits = ({ componentContent, componentEventId, trackEvent }: ExclusiveBenefitsProps) => {
  const { isTabletView } = useGlobalContext()

  const [isKfModalOpen, { onOpen: onOpenKfModal, onClose: onCloseKfModal }] = useToggle()
  const [isPerksModalOpen, { onOpen: onOpenPerksModal, onClose: onClosePerksModal }] = useToggle()
  const [selectedId, setSelectedId] = useState<PerksModalBrandName>('sia')

  const { header, items: benefitItems } = componentContent as ComponentContent

  const items = useMemo(() => {
    // Disable scoot for now until scoot PNR launches
    // TODO: Yanto -> remove this once scoot is launched
    return benefitItems.filter((benefitItem) => benefitItem.id !== 'scoot')
  }, [benefitItems])

  const handleClick = (data: ExclusiveBenefitData, index: number) => {
    trackEvent({
      attributeId: componentEventId,
      attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
      attributeValue: {
        id: data.id,
        cardIndex: index,
        cardType: EVENTS.EXCLUSIVE_BENEFITS,
      },
      container: componentEventId,
    })

    const isKrisFlyer = data.id === 'kf'
    if (isKrisFlyer) {
      onOpenKfModal()
    } else {
      setSelectedId(data.id as PerksModalBrandName)
      onOpenPerksModal()
    }
  }

  const hasScootPNR = useMemo(() => {
    return items.some((item) => item.id === 'scoot')
  }, [items])

  return (
    <Container className={s.exclusiveBenefits}>
      <h2 className={s.title}>{header}</h2>
      {isTabletView ? (
        <CardSlider
          componentEventId={componentEventId}
          componentQueryData={{ exclusiveBenefits: items }}
          cardType="exclusiveBenefits"
          onItemClick={handleClick}
          trackEvent={trackEvent}
        />
      ) : (
        <div className={cn(s.grid, { [s.twoCol]: !hasScootPNR })}>
          {items.map((item, index) => (
            <ExclusiveBenefitsCard key={item.id} data={item} onClick={() => handleClick(item, index)} />
          ))}
        </div>
      )}
      {!isTabletView && (
        <ExposureTracker
          onExposure={() => {
            trackEvent?.({
              attributeId: componentEventId,
              attributeType: EVENTS.ATTRIBUTES_TYPE.CARD,
              eventType: EVENTS.TYPE.EXPOSURE,
              attributeValue: {
                cardType: EVENTS.EXCLUSIVE_BENEFITS,
                cards: items?.map(({ id }, cardIndex: number) => ({
                  id,
                  cardIndex,
                })),
              },
            })
          }}
        />
      )}
      {isKfModalOpen && <KfRewardsModal open={isKfModalOpen} onClose={onCloseKfModal} />}
      {isPerksModalOpen && (
        <ExclusivePassengerPerksModal
          brand={selectedId}
          open={isPerksModalOpen}
          onClose={onClosePerksModal}
          hasScootPNR={hasScootPNR}
        />
      )}
    </Container>
  )
}

export { ExclusiveBenefits }
