import React, { useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import posthog from 'posthog-js'

import { useSearchModal } from 'components/search-modal/hooks'
import {
  SearchAutocomplete,
  SearchAutocompleteResults,
  SearchAutocompleteSeeMore,
} from 'components/search-autocomplete'
import { InitDropdown } from 'components/search-autocomplete/init-dropdown'
import { SearchModal } from 'components/search-modal'

import useSearch from 'lib/hooks/useSearch'
import useTranslation from 'lib/hooks/useTranslation'

import { getAutoSuggestFilter, searchExposureOnEmptyResults } from 'lib/utils/search'
import { useGlobalContext } from 'lib/context/global-context'
import { buildPath, logError } from 'lib/utils'

import { SEARCH_ROUTE } from 'lib/constants/routes'
import { DATA_LAYER_EVENT } from 'lib/constants'
import { EVENTS } from 'lib/constants/events'

import { AUTO_SUGGEST_SEACH_QUERY } from 'gql/queries/search'

type SearchBarProps = {
  trackEvent?: TrackEventType
}

export function SearchBar({ trackEvent }: SearchBarProps) {
  const router = useRouter()

  const { t } = useTranslation()
  const { isSearchModalOpen, openSearchModal, closeSearchModal, loader } = useSearchModal()
  const { isMobileView } = useGlobalContext()

  const {
    query,
    handleChange: doSearch,
    data,
    error,
    searching,
  } = useSearch({
    filter: () => getAutoSuggestFilter({}),
    searchQuery: AUTO_SUGGEST_SEACH_QUERY.query,
    onSearchQueryComplete: (queryData) => {
      // exposure event will be triggered by component if there are results
      searchExposureOnEmptyResults({
        query,
        destinations: queryData?.[AUTO_SUGGEST_SEACH_QUERY.queryName.destinations]?.destinations,
        products: queryData?.[AUTO_SUGGEST_SEACH_QUERY.queryName.products]?.products,
        trackEvent,
      })
    },
  })

  const handleSearchClick = () => {
    if (!isMobileView) return

    openSearchModal?.()
  }

  useEffect(() => {
    if (error) {
      logError(error)
    }
  }, [error])

  const searchResults = useMemo(
    () => ({
      destinations: data?.[AUTO_SUGGEST_SEACH_QUERY.queryName.destinations]?.destinations,
      products: data?.[AUTO_SUGGEST_SEACH_QUERY.queryName.products]?.products,
    }),
    [data]
  )

  const hideSeeMore =
    !Object.values(searchResults).reduce((total, item = []) => item.length + total) && searching

  const onSearchAutoSuggestOptionClick = (item: any, type?: string) => {
    if (type !== 'query') return

    const payload = {
      [DATA_LAYER_EVENT.ECOMMERCE.SEARCH_TERM]: item,
    }
    window?.dataLayer?.push({
      event: DATA_LAYER_EVENT.SEARCH,
      ...payload,
    })
    posthog.capture(DATA_LAYER_EVENT.SEARCH, payload)
  }

  return (
    <>
      <SearchAutocomplete
        value={query}
        actAsButton={isMobileView}
        hideButton
        hasSearchIcon
        onClick={handleSearchClick}
        variant="hp-blur"
        size={isMobileView ? 'large' : 'x-large'}
        placeholder={t('t.destinationAttractionActivity', { ns: 'common' })}
        onChange={doSearch}
        onSubmit={() => router.push(buildPath(SEARCH_ROUTE, {}, { keyword: query }))}
        trackEvent={trackEvent}
        initialStateComponent={
          <InitDropdown>
            <InitDropdown.RecentSearches onSelect={onSearchAutoSuggestOptionClick} />
            <InitDropdown.TrendingSearches onSelect={onSearchAutoSuggestOptionClick} />
          </InitDropdown>
        }
        loading={isMobileView && loader}
      >
        <SearchAutocompleteResults loading={searching} options={searchResults} className="mt-2" />
        <SearchAutocompleteSeeMore
          value={query}
          onClick={() => router.push(buildPath(SEARCH_ROUTE, {}, { keyword: query }))}
          hidden={hideSeeMore}
          trackEvent={trackEvent}
          flatButton={!searchResults.destinations?.length && !searchResults.products?.length}
        />
      </SearchAutocomplete>
      <SearchModal
        open={isSearchModalOpen}
        closeModal={closeSearchModal}
        initStateCompType="destination"
        onChange={doSearch}
        query={query}
        data={data}
        loading={searching}
        dontTrackOnSubmit
        onSubmit={(query: string) => {
          trackEvent?.({
            attributeId: EVENTS.AUTO_SUGGEST_SEARCH_INPUT,
            attributeType: EVENTS.ATTRIBUTES_TYPE.INPUT,
            isCompact: false,
            attributeValue: {
              query,
            },
          })
          query && router.push(buildPath(SEARCH_ROUTE, {}, { keyword: query }))
        }}
        trackEvent={trackEvent}
      />
    </>
  )
}
