import cn from 'classnames'

import { Icon, IconNames } from 'ui/icon'

import s from './styles.module.scss'

export type TagCardProps = {
  variant?: 'default' | 'blur'
  iconName: IconNames
  size?: 'medium' | 'large'
  label: string
  onClick?: () => void
}

const TagCard = ({ variant = 'default', size = 'large', iconName, label, onClick }: TagCardProps) => {
  return (
    <button type="button" onClick={onClick} className={cn(s.tagCard, s[size], { [s[variant]]: true })}>
      <Icon name={iconName || 'shooting-star'} size="semi-large" />
      <span className={s.label}>{label}</span>
    </button>
  )
}

export { TagCard }
