import { useMemo } from 'react'

import { CardSlider } from 'components/card-slider'
import { DynamicComponentProps } from 'components/dynamic-components/types'

import useAuth from 'lib/hooks/useAuth'

import s from './styles.module.scss'

type OngoingDealsProps = DynamicComponentProps & {
  trackEvent: TrackEventType
}

const OngoingDeals = ({ componentEventId, componentContent, trackEvent }: OngoingDealsProps) => {
  const { header, items = [] } = componentContent

  const { isLoggedIn } = useAuth()

  const displayedOngoingDeals = useMemo(() => {
    if (isLoggedIn) {
      return items
    }

    return [{ type: 'custom' }, ...items]
  }, [items, isLoggedIn])

  return (
    <div className={s.ongoingDeals}>
      <CardSlider
        cardType="ongoingDeals"
        componentEventId={componentEventId}
        componentQueryData={{
          ongoingDeals: displayedOngoingDeals,
        }}
        componentContent={{ header: [header] }}
        itemPerSlide={3}
        trackEvent={trackEvent}
      />
    </div>
  )
}

export { OngoingDeals }
